import React from 'react';
import { Button } from 'antd';
import QueueAnim from 'rc-queue-anim';
import Texty from 'rc-texty';
import 'rc-texty/assets/index.css';

class Banner extends React.PureComponent {
  render() {
    const { ...currentProps } = this.props;
    const { dataSource } = currentProps;
    delete currentProps.dataSource;
    delete currentProps.isMobile;
    const children = dataSource.textWrapper.children.map((item) => {
      const { name, texty, ...$item } = item;
      if (name.match('button')) {
        return (
          <div style={{ display: 'flex', margin: 'auto', width: '300px' }} key={name}>
          {window.location.host.includes('cn') ?
              <a href='//v3.yingtaoyun.com/login' key={name} {...$item}>
              v3登录
              </a>
          : ''}
          <a href={window.location.host.includes('com')?'//v3.yingtaoyun.com/login':'http://www.yingtaoyun.cn/login.html'} key={name} {...$item}>
            {item.children}
          </a>
          </div>
        );
      }

      return (
        <div key={name} {...$item}>
          {texty ? (
            <Texty type="mask-bottom">{item.children}</Texty>
          ) : (
            item.children
          )}
        </div>
      );
    });
    return (
      <div {...currentProps} {...dataSource.wrapper}>
        <video autoPlay muted src="https://erp-95.cdn.yingtaoyun.com/yingtao/v3/home/img/banner.mp4"></video>
        <QueueAnim
          key="QueueAnim"
          type={['bottom', 'top']}
          delay={200}
          {...dataSource.textWrapper}
        >
          {children}
        </QueueAnim>
        <img src="https://erp-95.cdn.yingtaoyun.com/yingtao/v3/home/img/arrow-down-o.svg" className="arrow" />
      </div>
    );
  }
}
export default Banner;
